<template>
  <div>
    <b-card>
      <b-row>
        <b-col cols="6">
          <b-form-group>
            <div class="d-flex justify-content-between">
              <label>{{ $t('PROFIL.FIRSTNAME') }} :</label>
            </div>
            <b-form-input
              v-model="user.prenom"
              readonly
            />
          </b-form-group>
        </b-col>

        <b-col cols="6">
          <b-form-group>
            <div class="d-flex justify-content-between">
              <label for="password">{{ $t('PROFIL.LASTNAME') }} :</label>
            </div>
            <b-form-input
              v-model="user.nom"
              readonly
            />
            <b-form-text>{{ }}</b-form-text>
          </b-form-group>
        </b-col>

        <b-col cols="12">
          <b-form-group>
            <div class="d-flex justify-content-between">
              <label for="password">{{ $t('PROFIL.EMAIL') }} :</label>
            </div>
            <b-form-input
              v-model="user.email"
              readonly
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-form @submit.prevent="updateAccount()">
        <b-row v-if="canAccesFacture">
          <b-col md="6">
            <b-form-checkbox
              v-model="user.factureParMail"
              value="true"
              class="mt-2"
            >
              {{ $t('PROFIL.FACTURE_PAR_MAIL') }}
            </b-form-checkbox>
          </b-col>
          <b-col cols="6">
            <b-form-group>
              <div class="d-flex justify-content-between">
                <label>{{ $t('PROFIL.EMAILFACTURE') }} :</label>
              </div>
              <b-form-input
                v-model="user.emailFacture"
              />
            </b-form-group>
          </b-col>

          <b-col>
            <b-button
              v-if="loadingAccount"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              type="button"
              class="mr-1 mt-2"
              disabled
            >
              <b-spinner small /> {{ $t('LOADING_BUTTON') }} ...
            </b-button>

            <b-button
              v-else
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              type="submit"
              variant="primary"
              class="mr-1 mt-2"
            >
              {{ $t('PROFIL.PASSWORD_UPDATE_BUTTON') }}
            </b-button>

          </b-col>
        </b-row>

      </b-form>

    </b-card>

    <b-card>
      <b-form @submit.prevent="updatePassword()">
        <b-row>
          <b-col cols="6">
            <b-form-group>
              <div class="d-flex justify-content-between">
                <label for="password">{{ $t('PROFIL.PASSWORD') }} :</label>
              </div>

              <b-input-group
                class="input-group-merge"
                :class="errors['password'] ? 'is-invalid':null"
              >
                <b-form-input
                  id="password"
                  v-model="password"
                  :state="errors['password'] ? false:null"
                  class="form-control-merge"
                  :type="passwordFieldType"
                  name="password"
                />
                <b-input-group-append is-text>
                  <feather-icon
                    class="cursor-pointer"
                    :icon="passwordToggleIcon"
                    @click="togglePasswordVisibility"
                  />
                </b-input-group-append>
              </b-input-group>
              <small
                v-if="errors['password']"
                class="text-danger"
              >{{ errors['password'] }}</small>
            </b-form-group>
          </b-col>

          <b-col cols="6">
            <b-form-group>
              <div class="d-flex justify-content-between">
                <label for="passwordConfirm">{{ $t('PROFIL.PASSWORD_CONFIRM') }} :</label>
              </div>

              <b-input-group
                class="input-group-merge"
                :class="errors['passwordConfirm'] ? 'is-invalid':null"
              >
                <b-form-input
                  id="passwordConfirm"
                  v-model="passwordConfirm"
                  :state="errors['passwordConfirm'] ? false:null"
                  class="form-control-merge"
                  :type="passwordFieldType"
                  name="passwordConfirm"
                />
                <b-input-group-append is-text>
                  <feather-icon
                    class="cursor-pointer"
                    :icon="passwordToggleIcon"
                    @click="togglePasswordVisibility"
                  />
                </b-input-group-append>
              </b-input-group>
              <small
                v-if="errors['passwordConfirm']"
                class="text-danger"
              >{{ errors['passwordConfirm'] }}</small>
            </b-form-group>
          </b-col>

          <b-col cols="12">
            <b-form-text>{{ passwordMessage }}</b-form-text>
          </b-col>

          <b-col>

            <b-button
              v-if="loading"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              type="button"
              class="mr-1 mt-2"
              disabled
            >
              <b-spinner small /> {{ $t('LOADING_BUTTON') }} ...
            </b-button>

            <b-button
              v-else
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              type="submit"
              variant="primary"
              class="mr-1 mt-2"
            >
              {{ $t('PROFIL.PASSWORD_UPDATE_BUTTON') }}
            </b-button>

          </b-col>

        </b-row>
      </b-form>
    </b-card>
  </div>
</template>

<script>

import {
  BCard, BForm, BRow, BCol, BFormGroup, BFormInput, BButton, BInputGroup, BInputGroupAppend, BFormText, BFormCheckbox, BSpinner,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'

export default {
  components: {
    BCard,
    BForm,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BButton,
    BInputGroup,
    BInputGroupAppend,
    BFormText,
    BFormCheckbox,
    BSpinner,
  },
  directives: {
    Ripple,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      user: {
        id: null,
        nom: '',
        prenom: '',
        email: '',
        emailFacture: '',
        factureParMail: false,
      },
      password: '',
      passwordConfirm: '',
      passwordMessage: '',
      passwordRegex: '',
      errors: [],
      loading: false,
      loadingAccount: false,
      canAccesFacture: false,
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    activeSociete() {
      return this.$store.getters['societe/getActiveSociete']
    },
  },
  watch: {
    activeSociete() {
      this.$store.dispatch('application/canAccess', { idSociete: this.activeSociete.idSociete, droit: 'facture' }).then(r => { this.canAccesFacture = r })
    },
  },

  mounted() {
    const userData = JSON.parse(localStorage.getItem('userData'))
    if (userData) {
      this.user.id = userData.id
      this.user.nom = userData.nom
      this.user.prenom = userData.prenom
      this.user.email = userData.email
      this.user.emailFacture = userData.emailFacture
      this.user.factureParMail = userData.factureParMail
    }

    this.$store.dispatch('application/canAccess', { idSociete: this.activeSociete.idSociete, droit: 'facture' }).then(r => { this.canAccesFacture = r })

    const passwordRules = JSON.parse(localStorage.getItem('passwordRules'))
    this.passwordRegex = passwordRules.rules

    this.passwordMessage = passwordRules.errorMsg
  },
  methods: {
    updatePassword() {
      const that = this
      this.errors = []
      if (!this.password) {
        this.errors.password = 'Ce champs est obligatoire'
      } else if (!this.passwordConfirm) {
        this.errors.passwordConfirm = 'Ce champs est obligatoire'
      } else if (this.password !== this.passwordConfirm) {
        this.errors.password = 'Ces champs ne sont pas identiques'
        this.errors.passwordConfirm = 'Ces champs ne sont pas identiques'
      } else if (!this.password.match(this.passwordRegex)) {
        this.errors.password = 'La règle de mot de passe n \'est pas respectée'
        this.errors.passwordConfirm = 'La règle de mot de passe n \'est pas respectée'
      } else {
        this.loading = true
        that.$store.dispatch('utilisateur/updatePassword', { password: this.password })
          .then(response => {
            if (response.status === 201) {
              that.$swal({
                title: that.$i18n.t('USER_DROPDOWN.MODAL_PASSWORD.SUCCESS.TITLE'),
                text: that.$i18n.t('USER_DROPDOWN.MODAL_PASSWORD.SUCCESS.TEXT'),
                icon: 'success',
                customClass: {
                  confirmButton: 'btn btn-primary',
                },
                buttonsStyling: false,
              })
              this.password = ''
              this.passwordConfirm = ''
              this.loading = false
            } else {
              that.$swal({
                title: that.$i18n.t('USER_DROPDOWN.MODAL_PASSWORD.ERROR.TITLE'),
                text: that.$i18n.t('USER_DROPDOWN.MODAL_PASSWORD.ERROR.TEXT'),
                icon: 'error',
                customClass: {
                  confirmButton: 'btn btn-primary',
                },
                buttonsStyling: false,
              })
              this.loading = false
            }
          })
          .catch(() => {
            that.$swal({
              title: that.$i18n.t('USER_DROPDOWN.MODAL_PASSWORD.ERROR.TITLE'),
              text: that.$i18n.t('USER_DROPDOWN.MODAL_PASSWORD.ERROR.TEXT'),
              icon: 'error',
              customClass: {
                confirmButton: 'btn btn-primary',
              },
              buttonsStyling: false,
            })
            this.loading = false
          })
      }
    },
    updateAccount() {
      this.loadingAccount = true
      if (this.user.emailFacture !== '') {
        if (this.user.emailFacture === '') this.user.emailFacture = null
        this.$store.dispatch('utilisateur/updateAccount', { id: this.user.id, factureParMail: Boolean(this.user.factureParMail), emailFacture: this.user.emailFacture })
          .then(response => {
            const userData = JSON.parse(localStorage.getItem('userData'))
            userData.emailFacture = response.data.emailFacture
            userData.factureParMail = response.data.factureParMail

            localStorage.setItem('userData', JSON.stringify(userData))

            this.$swal({
              title: this.$i18n.t('USER_DROPDOWN.UPDATE_ACCOUNT.SUCCESS.TITLE'),
              text: this.$i18n.t('USER_DROPDOWN.UPDATE_ACCOUNT.SUCCESS.TEXT'),
              icon: 'success',
              customClass: {
                confirmButton: 'btn btn-primary',
              },
              buttonsStyling: false,
            })
            this.loadingAccount = false
          })
          .catch(() => {
            this.$swal({
              title: this.$i18n.t('USER_DROPDOWN.UPDATE_ACCOUNT.ERROR.TITLE'),
              text: this.$i18n.t('USER_DROPDOWN.UPDATE_ACCOUNT.ERROR.TEXT'),
              icon: 'error',
              customClass: {
                confirmButton: 'btn btn-primary',
              },
              buttonsStyling: false,
            })
            this.loadingAccount = false
          })
      }
    },
  },
}
</script>
